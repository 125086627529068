import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import ArticlesComponent from "../components/articles";
import PageHeader from '../components/PageHeader-Secondary'
import Image from '../components/Image'
import '../components/product-card.css'

const PriceListPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
    <PageHeader
      /*title={title}*/
      title="Prisliste"
      subtitle="Her er en prisliste over det jeg lager mest av. Hvis du ikke finner det du leter etter er det bare å ta kontakt!"
      backgroundImage=""
    />

    <section className="row section container">
    {data.strapiPrices.prices.map(price => (
        <div className="column3">
            <div class="product-card" key={price.id}>
                <div class="badge">{price.price}</div>
                <div class="product-tumb">
                    <Image src={price.image.publicURL} alt={price.title} />
                </div>
                <div class="product-details">
                    <span class="product-catagory">{price.subtitle}</span>
                    <h4>{price.title}</h4>
                    <p>{price.description}</p>
                    <div class="product-bottom-details">
                        <div class="product-price"><small>{price.previousPrice}</small>{price.price}</div>
                        <div class="product-links">
                            <a href="https://www.lowekonditori.no/#kontakt">Kontakt meg</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ))}
    </section>


    </Layout>
  )
};

const query = graphql`
  query {
    strapiPrices {
          prices {
            title
            subtitle
            price
            description
            previousPrice
            image {
              publicURL
              childImageSharp {
                  fixed(width: 800, height: 720) {
                src
                }
            }
        }
        }
    }
  }
`;

export default PriceListPage;
